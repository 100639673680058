import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import Home from './routes/Home';
import About from './routes/About';
import Footer from "./components/Footer";
import BudgetmatePrivacyPolicy from "./routes/budgetmate/PrivacyPolicy";
import { useEffect } from "react";

function App() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname === "/budgetmate/privacy-policy";

  useEffect(() => {
    if (location.pathname.includes("budgetmate")) {
      document.body.style.backgroundColor = "#4441f2";
    } else {
      document.body.style.backgroundColor = "#090909";
    }
  }, [location.pathname]);
  
  return (
    <div className="app-wrapper">
      {!hideHeaderFooter && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/budgetmate/privacy-policy"  element ={<BudgetmatePrivacyPolicy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
